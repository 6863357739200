$(document).ready(function () {
  $('#drugs_save').click(function(e){
    e.preventDefault();
    var grid = $('#drugs-grid').data('kendoGrid');
    var dataSource = grid.dataSource;
    var state = {
        page: dataSource.page(),
        pageSize: dataSource.pageSize(),
        sort: dataSource.sort(),
        filter: dataSource.filter()
    };
    localStorage['kendo-grid-options'] = kendo.stringify(state);
    var test = localStorage['kendo-grid-options']
  });

  $('#drugs_clear').click(function () {
    window.localStorage.clear();
  });

  $('#drugs-grid').kendoGrid({
    toolbar: ['excel'],
    excel: {
      filterable: true,
      fileName: 'drugs_export'
    },
    dataSource: {
      transport: {
        read: {
          url: '/admin/drugs.json',
          dataType: 'JSON'
        }
      },
      pageSize: 50
    },
    schema: {
      model: {
        id: 'id'
      }
    },
    resizable: false,
    pageable: {
      pageSizes: [10, 25, 50, 100, 'All'],
      input: true,
      numeric: false,
      refresh: true
    },
    sortable: true,
    scrollable: false,
    filterable: {
      mode: 'row'
    },
    columns: [
      {
        field: 'id',
        title: '#',
        width: '12.5%',
        filterable: {
          cell: {
            enabled: true
          }
        }
      },
      {
        field: 'name',
        title: 'Name',
        width: '23%',
        filterable: {
          cell: {
            enabled: true
          }
        },
        template: "<a href='/admin/drugs/#=id#'>#=name#</a>"
      },
      {
        field: 'drug_class_name',
        title: 'Class',
        width: '23%',
        filterable: {
          cell: {
            enabled: true
          }
        },
        template: "<a href='/admin/drug_classes/#=drug_class_id#'>#=drug_class_name#</a>"
      },
      {
        field: 'approved',
        title: 'Approved',
        width: '7%',
        attributes: {
          style: 'text-align: center'
        },
        filterable: false
      },
      {
        field: 'reviewed',
        title: 'Reviewed',
        width: '7%',
        attributes: {
          style: 'text-align: center'
        },
        filterable: false
      },
      {
        field: 'primary',
        title: 'Primary',
        width: '7%',
        attributes: {
          style: 'text-align: center'
        },
        filterable: false
      },
      {
        field: 'comed',
        title: 'Comed',
        width: '7%',
        attributes: {
          style: 'text-align: center'
        },
        filterable: false
      },
      {
        title: 'Actions',
        width: '13.5%',
        template: "<a href='/admin/drugs/#=id#' class='drug_link' target='_blank'>View</a> | <a href='/admin/drugs/#=id#/edit' target='_blank'>Edit</a> | <a data-confirm='Are you sure?' rel='nofollow' data-method='delete' href='/admin/drugs/#=id#'>Delete</a>",
      }

    ]
  });

  var grid = $('#drugs-grid').data('kendoGrid');
  var state = localStorage['kendo-grid-options'];
  if (state) {
      var data = JSON.parse(state);
      var options = grid.options;
      options.dataSource.page = data.page;
      options.dataSource.pageSize = data.pageSize;
      options.dataSource.sort = data.sort;
      options.dataSource.filter = data.filter;
      grid.destroy();
      $('#drugs-grid')
     .empty()
     .kendoGrid(options);
  }
});
