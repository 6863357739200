'use strict';

angular
  .module('checker.data')
  .factory('CheckerData', CheckerData);

CheckerData.$inject = ['$q', '$http', '$timeout', 'API_META'];

function CheckerData($q, $http, $timeout, API_META) {

  var availableDrugs           = [],
      availableDrugClassNames  = [],
      availableDrugTradeNames  = [],
      availableComeds          = [],
      availableComedClassNames = [],
      availableComedTradeNames = [],
      availableInteractions    = [];

  return {
    fetchApiDrugs: fetchApiDrugs,
    fetchDrugClassNames: fetchDrugClassNames,
    fetchInteractions: fetchInteractions,
    getComeds: function(){
      return availableComeds;
    },
    getDrugs: function(){
      return availableDrugs;
    },
    getInteractions: function(){
      return availableInteractions;
    }
  };

  /////////////////////////////////////////

  function fetchApiDrugs() {
    var data = [];
    return fetchDrugs().then(function(drugs){
      availableDrugs = drugs;
      data[0] = drugs;
      return fetchDrugClassNames().then(function(dClassData) {
        availableDrugClassNames = dClassData;
        data[1] = dClassData;
        return fetchComeds().then(function(comeds) {
          availableComeds = comeds;
          data[2] = comeds;
          return fetchComedClassNames().then(function(cClassData) {
            availableComedClassNames = cClassData;
            data[3] = cClassData;
            return fetchDrugTradeNames().then(function(dTradeData) {
              availableDrugTradeNames = dTradeData;
              data[4] = dTradeData;
              return fetchComedTradeNames().then(function(cTradeData) {
                availableComedTradeNames = cTradeData;
                data[5] = cTradeData;
                  return data;
              });              
            });
          });
        });  
      });
    });
  }

  function fetchComedClassNames() {
    return makeDataRequest(API_META['COMED_CLASSES_ENDPOINT']).then(function(classNames){
      return classNames.data;
    });
  }

  function fetchDrugClassNames() {
    return makeDataRequest(API_META['DRUG_CLASSES_ENDPOINT']).then(function(classNames){
      return classNames.data;
    });
  }

  function fetchComeds() {
    if (window.location.href.indexOf('admin') > -1){
      return makeDataRequest(API_META['UNAPPROVED_AND_COMED_ENDPOINT']).then(function(co_drugs){
        return co_drugs.data;
      });
    } else {
      return makeDataRequest(API_META['COMEDS_ENDPOINT']).then(function(co_drugs){
        return co_drugs.data;
      });
    }
  }

  function fetchDrugs() {
    if (window.location.href.indexOf('admin') > -1){
      return makeDataRequest(API_META['UNAPPROVED_AND_PRIMARY_ENDPOINT']).then(function(drugs){
        return drugs.data;
      });
    } else {
      return makeDataRequest(API_META['DRUGS_ENDPOINT']).then(function(drugs){
        return drugs.data;
      });
    }
  }

  function fetchDrugTradeNames() {
    return makeDataRequest(API_META['PRIMARY_TRADE_NAMES_ENDPOINT']).then(function(tradeNames){
      return tradeNames.data;
    });
  }

  function fetchComedTradeNames() {
    return makeDataRequest(API_META['COMED_TRADE_NAMES_ENDPOINT']).then(function(comedTradeNames){
      return comedTradeNames.data;
    });
  }

  function fetchInteractions(){
    return makeDataRequest(API_META['INTERACTIONS_ENDPOINT']).then(function(interactions){
      availableInteractions = interactions.data;
      return interactions.data;
    });
  }

  function makeDataRequest(url) {
    var deferred = $q.defer();

    var req = {
      method: 'GET',
      url: url,
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': API_META['X-Api-Key']
      }
    };

    $http(req).then(function (data) {
      deferred.resolve(data);
    }, function(response){
      deferred.reject(response);
    });

    return deferred.promise;
  }

}
