$(document).ready(function(){

  $('.mobile-nav-icon').click(function() {
    $('.navigation').slideToggle();
    $(this).toggleClass('mobile-nav-icon-active');
  });

  window.addEventListener("resize", function() {
    if (screen.width < 1000 && screen.height > 1000) {
      if ($('.mobile-interaction-link').css('display') === 'block') {
        $('.navigation').hide();
      } else {
        $('.navigation').show();
      }
    }
  }, false);

  // Prescribing Resource Categories
  $('.category-list-tab h2.tab-heading').click(function() {
    $(this).next().slideToggle().toggleClass('active-tab');
    $(this).toggleClass('active-tab');
  });

  // Prescribing Resource Copy
  $('.copy-button').click(function() {
    var textAreaId = $(this).data('id')
    $(this).children().first().css({"visibility": "visible"})
    navigator.clipboard.writeText($(this).data('id'));
    setTimeout( () => {
      $(this).children().first().css({"visibility": "hidden"})
    }, 2000);
  });

  // Site News Header Buttons
  $('.header-button-group a').click(function() {
    console.log('hi')
    $('.header-button-group a').removeClass('button-group-active');
    $(this).toggleClass('button-group-active');
  });

});

