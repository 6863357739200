(function(){
  'use strict';

  angular
    .module('checker.filters')
    .filter('searchDrugs', searchDrugs);

  function searchDrugs(){
    return function (items, query) {
      if (!items || !items.length) return;
      var filtered = [];
      var letterMatch = new RegExp(query, 'i');
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if (query) {
          if (letterMatch.test(item.name.substring(0, query.length))) {
            filtered.push(item);
          }
        } else {
          filtered.push(item);
        }
      }
      return filtered;
    };
  }

})();
