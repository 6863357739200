(function () {
  'use strict';

  angular.module('checker.filters')
    .filter('groupByClass', groupByClass);

    function groupByClass() {
      var dividers = {};

      return function(input, orderType) {

        if (!input || !input.length) return;

        var output = [];

        if (orderType === 'className') {
          var previousClass,
              currentClass,
              item;

          var drugs = _.sortBy(input, 'drug_class_name');

          for (var i = 0, ii = drugs.length; i < ii && (item = drugs[i]); i++) {
            currentClass = item.drug_class_name;
            if (!previousClass ||
                currentClass != previousClass) {

              var dividerId = currentClass;

              if (!dividers[dividerId]) {
                dividers[dividerId] = {
                  isDivider: true,
                  divider: currentClass,
                };
              }

              output.push(dividers[dividerId]);
            }

            output.push(item);
            previousClass = currentClass;
          }
        } else if (orderType === "tradeName") {
          var previousTrade,
              currentTrade,
              item;

          for (var i = 0, ii = input.length; i < ii && (item = input[i]); i++) {

            if (item.drug_trade_names.length > 0) {
              _.forEach(item.drug_trade_names, function(trade) {
                currentTrade = trade;

                if (!previousTrade ||
                    currentTrade != previousTrade) {

                  var dividerId = currentTrade;

                  if (!dividers[dividerId]) {
                    dividers[dividerId] = {
                      isDivider: true,
                      divider: currentTrade
                    };
                    output.push(dividers[dividerId]);
                    // console.log(output);
                  }

                  previousTrade = currentTrade;
                }

              });
            } else {
              currentTrade = "Other";
              if (!previousTrade ||
                  currentTrade != previousTrade) {

                var dividerId = currentTrade;

                if (!dividers[dividerId]) {
                  dividers[dividerId] = {
                    isDivider: true,
                    divider: currentTrade
                  };
                  output.push(dividers[dividerId]);
                }

                previousTrade = currentTrade;
              }
            }
          }
          
        } else {
          output = _.sortBy(input, 'name');
        }

        return output;
      }
    }

})();
