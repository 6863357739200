(function () {
    'use strict';

  angular
    .module('checker.core')
    .config(config)
    .run(runApp);

  config.$inject = ['$httpProvider', '$provide'];

  function config($httpProvider, $provide) {
    $httpProvider.defaults.headers.common['Cache-Control'] = 'no-cache';
  }

  function runApp() {
  }

})();
