(function () {
  'use strict';

  angular
    .module('checker')
    .directive('scrollToTopWhen', ['$timeout', function ($timeout) {
      return function link (scope, element, attrs) {
        scope.$on(attrs.scrollToTopWhen, function () {
          $timeout(function () {
            angular.element(element)[0].scrollTop = 0;
          });
        });
      }
    }]);
})();
