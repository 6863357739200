// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'bootstrap'
Rails.start();
Turbolinks.start();
ActiveStorage.start();

require('channels')
import '@progress/kendo-ui';

require('redactor')
require('redactor-source')
require('redactor-scriptbuttons')
require('redactor-clips')
require('angular')
require('angular-sanitize')
require('lodash')
require('jquery-reveal')

function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('../components/checker_app/', true, /\.js$/));

import '../components/admin_custom';
import '../components/custom';
import '../components/drugs';
import '../components/drug_classes';
import '../components/interactions';
import '../components/trade_names';