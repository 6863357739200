$(document).ready(function () {

  $('#interactions_save').click(function(e){
    e.preventDefault();
    var grid = $('#interactions-grid').data('kendoGrid');
    var dataSource = grid.dataSource;
    var state = {
        page: dataSource.page(),
        pageSize: dataSource.pageSize(),
        sort: dataSource.sort(),
        filter: dataSource.filter()
    };
    localStorage['kendo-grid-options'] = kendo.stringify(state);
    var test = localStorage['kendo-grid-options']
  });

  $('#interactions_clear').click(function () {
    window.localStorage.clear();
  });

  $('#interactions-grid').kendoGrid({
    dataSource: {
      transport: {
        read: {
          url: '/admin/interactions.json',
          dataType: 'JSON'
        }
      },
      pageSize: 50
    },
    schema: {
      model: {
        id: 'id'
      }
    },
    resizable: false,
    pageable: {
      pageSizes: [10, 25, 50, 100, 500],
      input: true,
      numeric: false,
      refresh: true
    },
    sortable: true,
    scrollable: false,
    filterable: {
      mode: 'row'
    },
    columns: [
      {
        field: 'primary_drug_name',
        title: 'Primary Drug',
        width: '25%',
        filterable: {
          cell: {
            enabled: true
          }
        },
        template: "<a href='/admin/drugs/#=primary_drug_id#' class='interaction_link'>#=primary_drug_name#</a>"
      },
      {
        field: 'co_drug_name',
        title: 'Co-Drug Name',
        width: '25%',
        filterable: {
          cell: {
            enabled: true
          }
        },
        template: "<a href='/admin/drugs/#=co_drug_id#' class='interaction_link'>#=co_drug_name#</a>"
      },
      {
        field: 'interaction_name',
        title: 'Interaction Status',
        width: '15%',
        filterable: {
          cell: {
            enabled: true
          }
        }
      },
      {
        field: 'evidence_grade_name',
        title: 'Evidence Grade',
        width: '15%',
        filterable: {
          cell: {
            enabled: true
          }
        }
      },
      {
        title: 'Actions',
        width: '20%',
        template: "<a href='/admin/interactions/#=id#/copy' class='copy_link' target='_blank'>Copy</a> | <a href='/admin/interactions/#=id#' class='interaction_link' target='_blank'>View</a> | <a href='/admin/interactions/#=id#/edit' target='_blank'>Edit</a> | <a data-confirm='Are you sure?' rel='nofollow' data-method='delete' href='/admin/interactions/#=id#'>Delete</a>",
      }

    ]
  });

  var grid = $('#interactions-grid').data('kendoGrid');
  var state = localStorage['kendo-grid-options'];
  if (state && grid) {
      var data = JSON.parse(state);
      var options = grid.options;
      options.dataSource.page = data.page;
      options.dataSource.pageSize = data.pageSize;
      options.dataSource.sort = data.sort;
      options.dataSource.filter = data.filter;
      grid.destroy();
      $('#interactions-grid')
     .empty()
     .kendoGrid(options);
  }

});