(function () {
  'use strict';

  angular
    .module('checker.core')
    .constant('API_META', {
      'INTERACTIONS_ENDPOINT': '/api/v1/interactions',
      'DRUGS_ENDPOINT': '/api/v1/drugs',
      'DRUG_CLASSES_ENDPOINT': '/api/v1/drug_classes',
      'COMEDS_ENDPOINT': '/api/v1/comeds',
      'COMED_CLASSES_ENDPOINT': '/api/v1/comed_classes',
      'UNAPPROVED_AND_PRIMARY_ENDPOINT': '/api/v1/unapproved_and_primary',
      'UNAPPROVED_AND_COMED_ENDPOINT': '/api/v1/unapproved_and_comed',
      'PRIMARY_TRADE_NAMES_ENDPOINT': '/api/v1/primary_trade_names',
      'COMED_TRADE_NAMES_ENDPOINT': '/api/v1/comed_trade_names',
      'X-Api-Key': '81ef317341cc99f031b16be369d40df6'
    });

})();
