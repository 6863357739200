(function () {
  'use strict';

  angular
    .module('checker')
    .controller('CheckerCtrl', CheckerCtrl);

  CheckerCtrl.$inject = ['$scope', 'CheckerData', 'InteractionsCalculator', '$location', '$anchorScroll', '$timeout', '$window', '$http'];


  function CheckerCtrl($scope, CheckerData, InteractionsCalculator, $location, $anchorScroll, $timeout, $window, $http) {
    /* jshint validthis: true */

    var vm = this;
    vm.availableDrugs = [];
    vm.availableComedClassNames = [];
    vm.availableDrugClassNames = [];
    vm.availableDrugTradeNames = [];
    vm.availableComedTradeNames = [];
    vm.calculateCols = calculateCols;
    vm.calculatedInteractions = [];
    vm.calculateEvidenceGrade = calculateEvidenceGrade;
    vm.calcViewAllColumns = calcViewAllColumns;
    vm.calcViewAllRows = calcViewAllRows;
    vm.calcViewAllStatus = calcViewAllStatus;
    vm.checkPrimaryInts = false;
    vm.classRelatedDrugs = classRelatedDrugs;
    vm.clearDrugsSearch = clearDrugsSearch;
    vm.clearComedsSearch = clearComedsSearch;
    vm.comedications = [];
    vm.comedListItems = [];
    vm.comedsQuery = '';
    vm.drugsQuery = '';
    vm.generatePDF = generatePDF;
    vm.getTradeNames = getTradeNames;    
    vm.interactions = [];
    vm.interactionSummary = interactionSummary;
    vm.listItems = [];
    vm.makeComedList = makeComedList;
    vm.makeDrugsList = makeDrugsList;
    vm.orderDrugs = 'AZ';
    vm.orderComeds = 'AZ';
    vm.reportId = '';
    vm.resetCalculator = resetCalculator;
    vm.selectedClassName = '';
    vm.selectedComeds = [];
    vm.selectedDrugs = [];
    vm.selectedDrugTradeNames = [];
    vm.selectedComedTradeNames = [];
    vm.selectedPrimaries = [];
    vm.shouldShowDownload = shouldShowDownload;
    vm.showViewAllDrugs = true;
    vm.showViewAllInteractions = false;
    vm.tableCols = [];
    vm.tableView = tableView;
    vm.tableViewVisible = false;
    vm.toggleClass = toggleClass;
    vm.toggleDrug = toggleDrug;
    vm.toggleDrugInfo = toggleDrugInfo;
    vm.toggleDrugsInTrade = toggleDrugsInTrade;
    vm.toggleInteractionInfo = toggleInteractionInfo;
    vm.toggleIntChecker = toggleIntChecker;
    vm.toggleViewAll = toggleViewAll;
    vm.viewAllReport = viewAllReport;
    vm.updateViewAllInteractions = updateViewAllInteractions;

    activate();

    /////////////////////////////////////////

    function activate() {
      CheckerData.fetchApiDrugs().then(function(data) {
        vm.availableDrugs = data[0];
        vm.listItems = vm.availableDrugs;
        vm.availableDrugClassNames = data[1];
        vm.comedications = data[2];
        vm.comedListItems = vm.comedications;
        vm.availableComedClassNames = data[3];
        vm.availableDrugTradeNames = data[4];
        vm.availableComedTradeNames = data[5]

      });
      CheckerData.fetchInteractions().then(function(data) {
        vm.interactions = data;
        if (vm.selectedDrugs.length > 0) {
          vm.calculatedInteractions = InteractionsCalculator.checkForInteractions(vm.selectedDrugs, vm.selectedComeds, vm.checkPrimaryInts, false);
        }
      });
    }

    function calcTableViewInteractions() {
      vm.interactionCols = _.orderBy(vm.selectedDrugs, [function(selectedDrug) { return selectedDrug.name.toLowerCase() }], ['asc']);
      if (vm.selectedDrugs.length > 1) {
        if (vm.checkPrimaryInts) {
          vm.interactionRows = _.orderBy(_.union(vm.selectedDrugs, vm.selectedComeds), [function(selectedDrug) { return selectedDrug.name.toLowerCase() }], ['asc']);
        } else {
          vm.interactionRows = _.orderBy(vm.selectedComeds, [function(selectedDrug) { return selectedDrug.name.toLowerCase() }], ['asc']);
        }

      } else {
        vm.interactionRows = _.orderBy(vm.selectedComeds, [function(selectedDrug) { return selectedDrug.name.toLowerCase() }], ['asc']);
      }
      generateTableCells();
    }

    function calcViewAllColumns() {
      vm.interactionCols = _.orderBy(vm.selectedDrugs, [function(selectedDrug) { return selectedDrug.name.toLowerCase() }], ['asc']);
      return vm.interactionCols
    }

    function calcViewAllRows(drugClass) {
      vm.interactionRows = _.orderBy(vm.classRelatedDrugs(drugClass, vm.comedications), [function(selectedDrug) { return selectedDrug.name.toLowerCase() }], ['asc']);
      return vm.interactionRows
    }

    function calcViewAllStatus(drugId, coDrugId) {
      var interactionFound = _.find(vm.calculatedInteractions, function(interaction) {
        return ((interaction.co_drug_id === coDrugId && interaction.primary_drug_id === drugId) || (interaction.primary_drug_id === coDrugId && interaction.co_drug_id === drugId));
      });
      if (interactionFound) {
        return interactionFound;
      } else {
        return {id: 1000000, name: 'Dud', interaction_status: 1};
      }
    }

    function calculateCols(coDrugIndex) {
      return vm.tableCols[coDrugIndex];
    }

    function calculateEvidenceGrade(evidenceGrade) {
      var grade = 'Not set';
      switch (evidenceGrade) {
        case 1:
          grade = 'High';
          break;
        case 2:
          grade = 'Moderate';
          break;
        case 3:
          grade = 'Low';
          break;
        case 4:
          grade = 'Very Low';
          break;
      }
      return grade;
    }

    function checkSelectedTradeNames(type, drug) {
      var tradeNames = selectedTradeNames(type);
      if (tradeNames.length > 0) {
        _.forEach(tradeNames, function(tradeName) {
          if (_.includes(tradeName.connected_drugs, drug.id)) {
            tradeName.isSelected = false;
            spliceTradeName(tradeName, type);
          }
        });
      }
    }

    function classRelatedDrugs(drugClass, list) {
      return _.filter(list, function(drug) {
        return _.includes(drugClass.connected_drugs, drug.id);
      });
    }

    function clearComedsSearch() {
      vm.comedsQuery = '';
    }

    function clearDrugsSearch() {
      vm.drugsQuery = '';
    }

    function deselectTrade(trade, type) {
      var newSelection = _.reject(selectedTradeNames(type), function(tradeName) { return tradeName === trade; });
      var remainingDrugs = [];

      _.forEach(newSelection, function(tradeName) {
        remainingDrugs = _.union(remainingDrugs, tradeName.connected_drugs);
      });

      _.forEach(classRelatedDrugs(trade, relevantDrugs(type)), function(drug) {
        if (!_.includes(remainingDrugs, drug.id)) {
          drug.isSelected = false;
          drug.infoVisible = false;
          selectionSplice(drug, type);
        }
      });
      trade.isSelected = false;
      spliceTradeName(trade, type);
      vm.calculatedInteractions = InteractionsCalculator.checkForInteractions(vm.selectedDrugs, vm.selectedComeds, vm.checkPrimaryInts, false);
      if (vm.tableViewVisible) { resetTableView(); }
    }

    // function duplicateMessage(drug, type) {
    //   var message = type === 'DRUG' ? 'You have already selected ' + drug.name + ' as a co-medication drug. Please de-select the drug in the Co-medications column in order to select it as a primary drug.' : 'You have already selected ' + drug.name + ' as a primary drug. Please de-select the drug in the COVID Drugs column in order to select it as a co-medication.';
    //   alert(message);
    // }

    // function duplicateSelection(drugA, selectedList) {
    //   var theDuplicate = _.find(selectedList, function(drugB){
    //     return (drugB.id == drugA.id);
    //   });
    //   return (theDuplicate);
    // }

    function generateTableCells() {
      _.forEach(vm.interactionRows, function(coDrug, coDrugIndex) {
        vm.tableCols.push([]);
        _.forEach(vm.interactionCols, function(drug, drugIndex) {
          var interactionTest = _.find(vm.calculatedInteractions, function(interaction) {
            return ((interaction.co_drug_id === coDrug.id && interaction.primary_drug_id === drug.id) || (interaction.primary_drug_id === coDrug.id && interaction.co_drug_id === drug.id));
          });
          if (!interactionTest) {
            vm.tableCols[coDrugIndex].push({id: (1000000 + drugIndex), name: 'Dud', interaction_status: 1});
          } else {
            vm.tableCols[coDrugIndex].push(interactionTest);
          }
        });
      });
    }

    function generatePDF(){
      var downloadLinkRoot = '/downloads/interaction_reports.pdf?'
      _.forEach(vm.calculatedInteractions, function(interaction, index) {
        downloadLinkRoot += 'interaction_ids%5B%5D='
        downloadLinkRoot += ((interaction.id).toString());
        if (index !== vm.calculatedInteractions.length - 1) {
          downloadLinkRoot += '&';
        }
      });

      if (vm.reportId.length > 0) {
        downloadLinkRoot += '&report_id='
        downloadLinkRoot += (vm.reportId).toString();
      }

      var finalDownloadLink = downloadLinkRoot;
      $location.hash('');
      var url = $location.absUrl().replace('/checker' + $location.url(), finalDownloadLink);
      if (window.location.href.indexOf('admin') == -1){
        mixpanel.track('PDF Generated', {
          'report_url' : url
        });
      }
      $window.open(url, '_blank');
    }

    function getTradeNames(drug) {
      if (drug.drug_trade_names && drug.drug_trade_names.length > 0) {
        return drug.drug_trade_names.join(", ");
      } else {
       return 'Not Available';
      }
    }

    function groupByClass(drugs) {
      return _.sortBy(drugs, 'drug_class_name');
    }

    $scope.hyphenStripper = function(drug_class) {
      return drug_class.name.replace(/-/g, "");
    };


    function interactionSummary(interaction) {
      var summary = '';
      switch(interaction.interaction_status) {
        case 1:
          summary += 'Do Not Coadminister';
          break;
        case 2:
          summary += 'Potential Interaction';
          break;
        case 3:
          summary += 'Potential Weak Interaction';
          break;
        case 4:
          summary += 'No Interaction Expected';
          break;
        default:
          summary += 'No Interaction Expected';
      }
      return summary;
    }

    function makeComedList(sortType, referenceArray) {
      vm.comedListItems = [];
      $timeout(function(){
        vm.comedListItems = referenceArray;
        switch (sortType) {
          case 'AZ':
            vm.orderComeds = 'AZ';
            break;
          case 'className':
            vm.orderComeds = 'className';
            break;
          case 'tradeName':
            vm.orderComeds = 'tradeName';
            break;
          default:
            vm.orderComeds = 'AZ';
        }
      }, 10);
    }

    function makeDrugsList(sortType, referenceArray) {
      vm.listItems = [];
      $timeout(function(){
        vm.listItems = referenceArray;
        switch (sortType) {
          case 'AZ':
            vm.orderDrugs = 'AZ';
            break;
          case 'className':
            vm.orderDrugs = 'className';
            break;
          case 'tradeName':
            vm.orderDrugs = 'tradeName';
            break;
          default:
            vm.orderDrugs = 'AZ';
        }
      }, 10);
    }

    // function otherType (type) {
    //   return type === 'DRUG' ? 'COMED' : 'DRUG';
    // }

    function pushTradeName(trade, type) {
      switch (type) {
        case 'DRUG':
          vm.selectedDrugTradeNames.push(trade);
          break;
        case 'COMED':
          vm.selectedComedTradeNames.push(trade);
          break;
        default:
          return true;
      }
    }

    function relevantDrugs(type) {
      if (type === 'COMED'){
        return vm.comedications;
      } else if (type === 'DRUG') {
        return vm.availableDrugs;
      }
    }

    function resetCalculator() {
      resetSelection(vm.availableDrugs);
      resetSelection(vm.comedications);
      resetSelection(vm.availableDrugTradeNames);
      resetSelection(vm.availableComedTradeNames);
      vm.selectedDrugs = [];
      vm.selectedComeds = [];
      vm.selectedDrugTradeNames = [];
      vm.selectedComedTradeNames = [];
      vm.calculatedInteractions = [];
      vm.listItems = vm.availableDrugs;
      vm.comedListItems = vm.comedications;
      vm.comedsQuery = '';
      vm.drugsQuery = '';
      vm.reportId = '';
      vm.orderDrugs = 'AZ';
      vm.orderComeds = 'AZ';
      vm.tableViewVisible = false;
      resetTableView();
      $scope.$broadcast('checker_reset');
    }

    function resetSelection(drugs) {
      drugs.map(function (drug, index, original) {
        original[index]['isSelected'] = false;
        original[index]['infoVisible'] = false;
      });
    }

    function resetTableView() {
      vm.interactionCols = [];
      vm.interactionRows = [];
      vm.tableCols = [];
      calcTableViewInteractions();
    }

    function selectedDrugs(type) {
      if (type === 'COMED'){
        return vm.selectedDrugs;
      } else if (type === 'DRUG') {
        return vm.selectedComeds;
      }
    }

    function selectDrugViaTrade(drug, type) {
        drug.isSelected = true;
        drug.infoVisible = false;
        selectionPush(drug, type);
        if (window.location.href.indexOf('admin') == -1) {
          if (type == 'DRUG'){
            mixpanel.track('Primary drug', {
              'name': drug.name,
              'drug_class': drug.drug_class_name,
              'type': type
            });
          } else {
            mixpanel.track('Co-drug', {
              'name': drug.name,
              'drug_class': drug.drug_class_name,
              'type': type
            });
          }
        }
      vm.calculatedInteractions = InteractionsCalculator.checkForInteractions(vm.selectedDrugs, vm.selectedComeds, vm.checkPrimaryInts, false);
      if (vm.tableViewVisible) { resetTableView(); }
      return true;
    }

    function selectionPush(drug, type) {
      if (type === 'DRUG') {
        vm.selectedDrugs.push(drug);
        vm.selectedPrimaries.push(drug.id);
      } else if (type === 'COMED') {
        vm.selectedComeds.push(drug);
      }
    }

    function selectedTradeNames(type) {
      if (type === 'DRUG') {
        return vm.selectedDrugTradeNames;
      } else {
        return vm.selectedComedTradeNames;
      }
    }

    function selectionSplice(drug, type) {
      if (type === 'DRUG') {
        vm.selectedDrugs.splice(vm.selectedDrugs.indexOf(drug), 1);
        vm.selectedPrimaries.splice(vm.selectedPrimaries.indexOf(drug.id), 1);
      } else if (type === 'COMED') {
        vm.selectedComeds.splice(vm.selectedComeds.indexOf(drug), 1);
      }
    }

    $scope.$watch(function(scope) { return vm.calculatedInteractions},
                  function(newValue, oldValue){
                    var difference = _.differenceWith(newValue, oldValue, _.isEqual);

                    if (difference.length > 0) {
                      _.each(difference, function(diff){

                        if (window.location.href.indexOf('admin') === -1 && window.location.href.indexOf('view_all') === -1){
                          mixpanel.track('Interaction', {
                            'co_drug_name': diff.co_drug_name,
                            'co_drug_class': diff.co_drug_class,
                            'primary_drug_name': diff.primary_drug_name,
                            'primary_drug_class': diff.primary_drug_class,
                            'interaction status': diff.interaction_status,
                            'interaction colour': calculateInteractionColour(diff)
                          });
                        }
                      })
                    }
                  });

    function calculateInteractionColour(interaction) {
      switch (interaction.interaction_status) {
        case 1:
          return 'red';
          break;
        case 2:
          return 'amber';
          break;
        case 3:
          return 'yellow';
          break;
        case 4:
          return 'green';
          break;
        case 5:
          return 'grey';
          break;
        default:
          return 'no set';
      }
    }

    function shouldShowDownload(){
      if (vm.calculatedInteractions.length > 0) {
        return true
      } else {
        return false
      }
    }

    function spliceTradeName(trade, type) {
      switch (type) {
        case 'DRUG':
          vm.selectedDrugTradeNames.splice(vm.selectedDrugTradeNames.indexOf(trade), 1);
          break;
        case 'COMED':
          vm.selectedComedTradeNames.splice(vm.selectedComedTradeNames.indexOf(trade), 1);
          break;
        default:
          return true;
      }
    }

    function tableView() {
      calcTableViewInteractions();
      vm.tableViewVisible = true;
      $location.hash('table-view-wrap');

      $anchorScroll();
      if (window.location.href.indexOf('admin') == -1) {
        mixpanel.track('Table view');
      }
    }

    function toggleClass(className) {
      if (!className.showDrugs) {
        className.showDrugs = true;
      } else {
        className.showDrugs = false;
      }
    }

    function toggleDrug(drug, type) {
      if (!drug.isSelected) {
        drug.isSelected = !drug.isSelected;
        drug.infoVisible = false;
        selectionPush(drug, type);
        if (window.location.href.indexOf('admin') == -1) {
          if (type == 'DRUG'){
            mixpanel.track('Primary drug', {
              'name': drug.name,
              'drug_class': drug.drug_class_name,
              'type': type
            });
          } else {
            mixpanel.track('Co-drug', {
              'name': drug.name,
              'drug_class': drug.drug_class_name,
              'type': type
            });
          }
        }
      } else {
        drug.isSelected = !drug.isSelected;
        selectionSplice(drug, type);
        checkSelectedTradeNames(type, drug);
      }
      vm.calculatedInteractions = InteractionsCalculator.checkForInteractions(vm.selectedDrugs, vm.selectedComeds, vm.checkPrimaryInts, false);
      if (vm.tableViewVisible) { resetTableView(); }
    }

    function toggleDrugInfo(drug) {
      drug.infoVisible = !drug.infoVisible;
    }

    function toggleDrugsInTrade(trade, type) {
      var canToggleTrade = true
      var drugs = classRelatedDrugs(trade, relevantDrugs(type));

      if (!trade.isSelected) {
        _.forEach(drugs, function(drug) {
          if (!selectDrugViaTrade(drug, type)) { canToggleTrade = false; }
        });
        if (canToggleTrade) {
          trade.isSelected = true;
          pushTradeName(trade, type);
        }
      } else {
        deselectTrade(trade, type);
      }
    }

    function toggleInteractionInfo(interaction) {
      interaction.infoVisible = !interaction.infoVisible;
    }

    function toggleIntChecker(){
      vm.checkPrimaryInts = !vm.checkPrimaryInts;
      if (vm.selectedDrugs.length > 0) {
        vm.calculatedInteractions = InteractionsCalculator.checkForInteractions(vm.selectedDrugs, vm.selectedComeds, vm.checkPrimaryInts, false);
        if (vm.tableViewVisible) {
          resetTableView();
        }
      }
    }

    function toggleViewAll() {
      vm.showViewAllDrugs = !vm.showViewAllDrugs;
      vm.showViewAllInteractions = !vm.showViewAllInteractions;
    }

    function viewAllReport() {
      vm.showViewAllDrugs = !vm.showViewAllDrugs;
      vm.showViewAllInteractions = !vm.showViewAllInteractions;
      vm.makeComedList('className', vm.availableComedClassNames);
      vm.calculatedInteractions = InteractionsCalculator.checkForInteractions(vm.selectedDrugs, vm.selectedComeds, vm.checkPrimaryInts, true);
      vm.calculatedInteractions = vm.calculatedInteractions.flat();
      $window.scrollTo(0, 0);
    }

    function updateViewAllInteractions(drug) {
      if (!drug.isSelected) {
        drug.isSelected = !drug.isSelected;
        drug.infoVisible = false;
        selectionPush(drug, 'DRUG');
      } else {
        drug.isSelected = !drug.isSelected;
        selectionSplice(drug, 'DRUG');
      }
    }
  }

})();
