(function(){
  'use strict';

  angular
      .module('checker.data')
      .factory('InteractionsCalculator', InteractionsCalculator);

  InteractionsCalculator.$inject = ['CheckerData'];

  function InteractionsCalculator(CheckerData) {

    var calculatedInteractions = [],
        availableInteractions = [],
        selectedDrugs = [],
        selectedComeds = [];

    return {
      checkForInteractions: checkForInteractions
    };

    // ////////////////////////////////////////////////////////

    // // calculate interactions for selected drugs and comeds
    function checkForInteractions(drugs, comeds, checkPrimaryInts, viewAll){
      if (viewAll) {
        calculatedInteractions = [];
        selectedDrugs = drugs;
        availableInteractions = CheckerData.getInteractions();
        checkViewAllInteractions(drugs);
      } else {
        if (checkSelected) {
          calculatedInteractions = [];
          availableInteractions = CheckerData.getInteractions();
          selectedDrugs = drugs;
          selectedComeds = comeds;
          checkStandardInteractions(drugs, comeds);

          if (checkPrimaryInts) {
            checkCrossInteractions(drugs);
          }
        }
      }

      return calculatedInteractions;
    }

    function checkStandardInteractions (drugs, comeds) {
      drugs.forEach(function(drug){
        var pairsToCheck = [];

        comeds.forEach(function(comed){
          var drugPair = {};
          drugPair.covidDrugId = drug.id;
          drugPair.comedId = comed.id;
          pairsToCheck.push(drugPair);
        });

        checkDrugPairs(pairsToCheck);
      });
    }

    function checkCrossInteractions (drugs) {
      drugs.forEach(function(drug, i){
        var pairsToCheck = [];

        drugs.forEach(function(crossDrug, j){
          var drugPair = {};
          if (j > i){
            drugPair.covidDrugId = drug.id;
            drugPair.comedId = crossDrug.id;
            pairsToCheck.push(drugPair);
          }
        });
        checkDrugPairs(pairsToCheck);
      });
    }

    function checkViewAllInteractions (drugs) {
      drugs.forEach(function(drug){
        
        var interactions = _.filter(availableInteractions, function(interaction){
          return (interaction.primary_drug_id === drug.id);
        });
        if (!!interactions) {
          calculatedInteractions.push(interactions);
        }
      });
    }

    // // check for interaction hits for a passed in array of drug/comed IDs
    function checkDrugPairs (pairs) {
      pairs.forEach(function(combo){
        var interactions = _.find(availableInteractions, function(interaction){
          return (interaction.primary_drug_id === combo.covidDrugId && interaction.co_drug_id === combo.comedId);
        });
        if ( !!interactions ) {
          interactions.primary_drug_name = findDrug(interactions.primary_drug_id);
          interactions.co_drug_name = findCoMed(interactions.co_drug_id);
          interactions.co_drug_class = findCoMedClass(interactions.co_drug_id);
          interactions.primary_drug_class = findPrimaryDrugClass(interactions.primary_drug_id);
          calculatedInteractions.push(interactions);
        }
      });
    }

    // // check that at least one drug and one comed have been selected
    function checkSelected(drugs, comeds) {
      return !!(drugs.length > 1 || (drugs.length > 0 && comeds.length > 0));
    }

    // // Match drug ids to drug name
    function findDrug (drugId){
      var theDrug = _.find(selectedDrugs, function(drug){
        return (drug.id == drugId);
      });
      return (theDrug.name);
    }

    function findPrimaryDrugClass(drugId){
      var theDrug = _.find(selectedDrugs, function(drug){
        return (drug.id == drugId);
      });
      return (theDrug.drug_class_name);
    }

    // // Match co-med ids to drug name
    function findCoMed (drugId){
      var theCoMed = _.find(selectedComeds, function(coMed){
        return (coMed.id == drugId);
      });
      if ( !!theCoMed ) {
        return (theCoMed.name);
      } else {
        return (findDrug(drugId));
      }
    }

    function findCoMedClass(drugId){
      var theCoMed = _.find(selectedComeds, function(coMed){
        return (coMed.id == drugId);
      });
      if ( !!theCoMed ) {
        return (theCoMed.drug_class_name)
      }
    }
  }

})();
