$(document).ready(function () {
  $("#classes_save").click(function(e){
    e.preventDefault();
    var grid = $("#drug-classes-grid").data("kendoGrid");
    var dataSource = grid.dataSource;
    var state = {
        page: dataSource.page(),
        pageSize: dataSource.pageSize(),
        sort: dataSource.sort(),
        filter: dataSource.filter()
    };
    localStorage["kendo-grid-options"] = kendo.stringify(state);
    var test = localStorage["kendo-grid-options"]

  });

  $("#classes_clear").click(function () {
    window.localStorage.clear();
  });

  $("#drug-classes-grid").kendoGrid({
    toolbar: ["excel"],
    excel: {
      filterable: true,
      fileName: 'drug_classes_export'
    },
    dataSource: {
      transport: {
        read: {
          url: "/admin/drug_classes.json",
          dataType: "JSON"
        }
      },
      pageSize: 50
    },
    schema: {
      model: {
        id: "id"
      }
    },
    resizable: false,
    pageable: {
      pageSizes: [10, 25, 50, 100, 'All'],
      input: true,
      numeric: false,
      refresh: true
    },
    sortable: true,
    scrollable: false,
    filterable: {
      mode: "row"
    },
    columns: [
      {
       field: 'id',
       title: '#',
       width: '10%'
      },
      {
        field: "name",
        title: "Name",
        width: '25%',
        filterable: {
          cell: {
            enabled: true
          }
        }
      },
      {
        field: 'primary',
        title: 'Primary Drug Class',
        width: '20%',
        filterable: {
          cell: {
            enabled: false
          }
        }
      },
      {
        field: 'drugs_count',
        title: 'Drugs count',
        width: '20%',
        filterable: {
          cell: {  
            enabled: false
          }
        }
      },
      {
        title: "Actions",
        width: '25%',
        template: "<a href='/admin/drug_classes/#=id#' class='drug_link' target='_blank'>View</a> | <a href='/admin/drug_classes/#=id#/edit' target='_blank'>Edit</a> | <a data-confirm='Are you sure?' rel='nofollow' data-method='delete' href='/admin/drug_classes/#=id#'>Delete</a>",
      }

    ]
  });

  var grid = $("#drug-classes-grid").data("kendoGrid");
  var state = localStorage["kendo-grid-options"];
  if (state) {
      var data = JSON.parse(state);
      var options = grid.options;
      options.dataSource.page = data.page;
      options.dataSource.pageSize = data.pageSize;
      options.dataSource.sort = data.sort;
      options.dataSource.filter = data.filter;
      grid.destroy();
      $("#drug-classes-grid").empty().kendoGrid(options);
  }
});
