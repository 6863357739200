(function () {
    'use strict';

    angular.module('checker', [
      'checker.core',
      'checker.data',
      'checker.filters'
    ]);

})();
