import 'selectize/dist/css/selectize.bootstrap3.css';
import 'selectize/dist/js/standalone/selectize.js';

$(document).on('turbolinks:load', function() {
  $('.selectize').selectize()

  // posh selectize for creating has_many through inline

  // TRADE NAMES
  var tradeSelectizeCallback = null;
  $('.trade-name-modal').on('hide.bs.modal', function(e){

    if (tradeSelectizeCallback != null) {
      tradeSelectizeCallback();
      tradeSelectizeCallback = null;
    }

    $('#new_trade_name_sel').trigger('reset');
    var selectors = [$.rails.linkDisableSelector, $.rails.formEnableSelector].join(', ');
    $(selectors).each(function() {
      $.rails.enableElement(this);
    })
  });

  $('#new_trade_name_sel').on('submit', function(e){
    e.preventDefault();

    $.ajax({
      method: 'POST',
      dataType: 'json',
      url: $(this).attr('action'),
      data: $(this).serialize(),
      success: function(response) {
        tradeSelectizeCallback({value: response.id, text: response.name});
        tradeSelectizeCallback = null;

        $('.trade-name-modal').modal('toggle');
      }
    });

  });

  $('.trade-name-selectize').selectize({
    create: function(input, callback) {
      tradeSelectizeCallback = callback;

      $('.trade-name-modal').modal();
      $('#trade_name_name').val(input);
    }
  });

  // Admin header dropdowns
  $(".dropdown-toggle").click(function(e) {
    e.preventDefault();
    $('.dropdown-menu').not($(this).parent().children('.dropdown-menu')).hide();
    $(this).parent().children('.dropdown-menu').toggle();
  });

  // Redactor
  $(function() {
    $('#prescribing_resource_description, #interaction_summary, #banner_content, #site_news_content, \
      #team_member_biography, #committee_member_biography').redactor({
      plugins: ['source', 'scriptbuttons']
    });

    $('#interaction_description').redactor({
      plugins: ['source', 'scriptbuttons', 'clips']
    });
  });

  // Change icons on Kendo grid
  $(function() {
    $('.k-icon.k-i-filter-clear').addClass('k-i-close').removeClass('.k-i-filter-clear');
    $('.k-icon.k-i-arrow-end-left').addClass('k-i-seek-w').removeClass('.k-i-arrow-end-left');
    $('.k-icon.k-i-arrow-60-left').addClass('k-i-arrow-w').removeClass('.k-i-arrow-60-left');
    $('.k-icon.k-i-arrow-60-right').addClass('k-i-arrow-e').removeClass('.k-i-arrow-60-right');
    $('.k-icon.k-i-arrow-end-right').addClass('k-i-seek-e').removeClass('.k-i-arrow-end-right');
    $('.k-icon.k-i-reload').addClass('k-i-refresh').removeClass('.k-i-reload');
  });
});
